import { useCallback, useEffect, useRef } from "react"
import { hostWs } from "../constant/host";
import { useDispatch, useSelector } from "react-redux";
import { getStatusDevice } from "../redux/autoDoor/action";


const useWebsocket = () => {
    const ws = useRef(null)
    const timer = useRef(null)
    const dispatch = useDispatch()
    const { dataLogin } = useSelector(state=>state.login)

    const connectWs = useCallback((token,cid,langCode,sessionID)=>{
        ws.current = new WebSocket(hostWs, [token,cid,langCode,sessionID])
        ws.current.onopen = (e) => {
            console.log("connect websocket", e)
            clearTimeout(timer.current)
        }
        ws.current.onmessage = (e) => {
            console.log("message coming", e)
            console.log(JSON.parse(e.data))
            dispatch(getStatusDevice(JSON.parse(e.data)))
        }
        ws.current.onclose = (e) => {
            console.log("close ws", e)
            timer.current = setTimeout(()=>{
                connectWs(token,cid,langCode,sessionID)
            },10000)
        }
        ws.current.onerror = (e) => {
            console.log("close ws", e)
            ws.current?.close()

        }
    },[])

    useEffect(() => {
        let cid = dataLogin.id
        let sessionID = dataLogin.receptionID
        let langCode = "vn"
        let token = dataLogin.token
        connectWs(token,cid,langCode,sessionID)
        
    }, [connectWs]);
    return ws
}

export default useWebsocket