import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WrapPopupControl } from "./index.styles";

//imgs
import IcPinOff from "../../../../assets/images/IcPinOff";
import IcRefeshDoor from "../../../../assets/images/IcRefeshDoor";
import IcOnlineDevice from "../../../../assets/images/IcOnlineDevice";
import IcOfflineDevice from "../../../../assets/images/IcOfflineDevice";
import Button from "../../../Control/Button";
import IcInDoor from "../../../../assets/images/IcInDoor";
import IcOutDoor from "../../../../assets/images/IcOutDoor";
import IcPinOn from "../../../../assets/images/IcPinOn";

//action
import { openDoor } from "../../../../redux/autoDoor/action";

export const CHECK_IN = "CHECK_IN";
export const CHECK_OUT = "CHECK_OUT";

export default function PopupControlAutoDoor({
  className,
  refPopup,
  handleRefesh,
  handlePin,
  isPin,
  handleMouseLeave,
  handleMouseEnter,
}) {
  const dispatch = useDispatch();
  const [isOnlineCheckIn, setIsOnlineCheckIn] = useState(true)
  const [isOnlineCheckOut, setIsOnlineCheckOut] = useState(true)
  const [isDisableBtnCheckIn, setIsDisableBtnCheckIn] = useState(false)
  const [isDisableBtnCheckOut, setIsDisableBtnCheckOut] = useState(false)


  const { branchCode, dataDevice, deviceCheckIn, deviceCheckOut } = useSelector((state) => ({
    branchCode: state.login.dataLogin.branchCode,
    dataDevice: state.controlAutoDoor.dataDevice,
    deviceCheckIn: state.controlAutoDoor.deviceCheckIn,
    deviceCheckOut: state.controlAutoDoor.deviceCheckOut,

  }));

  const handleOpenCheckIn = () => {
    dispatch(openDoor({ branchCode: branchCode, deviceID: deviceCheckIn.id }));
    setIsDisableBtnCheckIn(true)
  };

  const handleOpenCheckOut = () => {
    dispatch(openDoor({ branchCode: branchCode, deviceID: deviceCheckOut.id }));
    setIsDisableBtnCheckOut(true)
  };

  useEffect(()=>{
    if (!isDisableBtnCheckIn) return
    let timer = setTimeout(()=>{
      setIsDisableBtnCheckIn(false)
    },5000)
    return ()=>{
      clearTimeout(timer)
    }
  },[isDisableBtnCheckIn])

  useEffect(()=>{
    if (!isDisableBtnCheckOut) return
    let timer = setTimeout(()=>{
      setIsDisableBtnCheckOut(false)
    },5000)
    return ()=>{
      clearTimeout(timer)
    }
  },[isDisableBtnCheckOut])

  useEffect(()=>{
    setIsOnlineCheckIn(deviceCheckIn.onlineStatus)
    setIsOnlineCheckOut(deviceCheckOut.onlineStatus)
  },[deviceCheckIn.onlineStatus, deviceCheckOut.onlineStatus])

  useEffect(()=>{
    if (!dataDevice?.deviceId) return
    if (dataDevice?.deviceId === deviceCheckIn.id){
        setIsOnlineCheckIn(dataDevice?.onlineStatus)
        return
    }
    if (dataDevice?.deviceId === deviceCheckOut.id){
        setIsOnlineCheckOut(dataDevice?.onlineStatus)
        return
    }
  },[dataDevice])

  return (
    <WrapPopupControl
      className={className}
      ref={refPopup}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      <div className="top">
        <div className="header">
          <p>Auto Door Control</p>
          <div className="action">
            <div className="icon" onClick={handleRefesh}>
              <IcRefeshDoor />
            </div>
            <div className="icon" onClick={handlePin}>
              {isPin ? <IcPinOn /> : <IcPinOff />}
            </div>
          </div>
        </div>
        <div className="device">
          <div className="item item1">
            { !isOnlineCheckIn ? (
              <IcOfflineDevice />
            ) : (
              <IcOnlineDevice />
            )}
            <span className={!isOnlineCheckIn ? "offline" : ""}>{deviceCheckIn.code || "-"}</span>
          </div>
          <div className="item">
            { !isOnlineCheckOut ? (
              <IcOfflineDevice />
            ) : (
              <IcOnlineDevice />
            )}
            <span className={ !isOnlineCheckOut ? "offline" : ""}>{deviceCheckOut.code || "-"}</span>
          </div>
        </div>
      </div>
      <div className="btn-container">
        <Button
          text={"Cửa vào"}
          className="btn-auto"
          iconBtn={<IcInDoor />}
          onClick={handleOpenCheckIn}
          isDisable={isDisableBtnCheckIn}
        />
        <Button
          text={"Cửa ra"}
          className="btn-auto"
          iconBtn={<IcOutDoor />}
          onClick={handleOpenCheckOut}
          isDisable={isDisableBtnCheckOut}
        />
      </div>
    </WrapPopupControl>
  );
}
