export const QC_ENV = 'qc'
export const DEV_ENV = 'dev'
export const PROD_ENV = 'product'

export const env = process.env.REACT_APP_SERVER_ENV || DEV_ENV
export const buildVersion = "07112022"
export const qcVersion = "none"
export const branch = "dev-v2.0"

console.log('environment: ', env)
console.log(`build: ${buildVersion}`)
console.log(`qc build: ${qcVersion}`)
console.log(`branch: ${branch}`)