import moment from 'moment'
import { env, DEV_ENV, QC_ENV, PROD_ENV } from './environmentType'
import { firebaseConfig } from '../firebase/realtimeDatabase'

export const { host, hostWs } = (() => {
    switch(env) {
        case DEV_ENV: {
            return {
                // host: 'https://citygymbackend-qgsymqlrda-as.a.run.app',
                host: 'https://citigym-dev.wee.vn/api',
                // host: "https://citigym.wee.vn/api", 
                hostWs: "wss://citigym-dev.wee.vn/websocket/ws/webPortal"
            }
        }
        case QC_ENV:{
            return {
                // host: 'https://citygymbackend-lbsnyjxwpa-as.a.run.app',
                host: "https://citigym-qc.wee.vn/api",
                hostWs: "wss://citigym-qc.wee.vn/websocket/ws/webPortal"
            }
        }
        case PROD_ENV:{
            return {
                host: "https://citigym.wee.vn/api",
                hostWs: "wss://citigym.wee.vn/websocket/ws/webPortal"
            }
        }
        default:
            return {
                host: "https://citigym.wee.vn/api",
                hostWs: "wss://citigym.wee.vn/websocket/ws/webPortal"
            }
    }
})()

export const apiGetFace = (id) => `${host}/member/getFace?id=${id}&time=${moment().unix()}`

export const apiGetImageFireBase = (imageRef) => `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${imageRef}&time=${moment().unix()}`

export const apiGetAvatar = (id) => `${host}/member/updateLog/getAvatar?id=${id}&time=${moment().unix()}`
export const apiGetPhoto = (path) => `${host}/photo/get?path=${path}&time=${moment().unix()}`