import React from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

// styles
import { WrapMain } from './index.styles'

// component
import Header from './Header'
import SideMenu from './SideMenu'
import Notify from './Notify'
import Loading from './Loading'
import ControlAutoDoor from './ControlAutoDoor'
import useWebsocket from '../../hook/useWebsocket'

const Main = () => {
    const { isLoading, autoDoorOpeningPermission } = useSelector(state => ({
        isLoading: state.loading.isLoading,
        autoDoorOpeningPermission: state.login.dataLogin.autoDoorOpeningPermission
    }))
    useWebsocket()
    return (
        <WrapMain>
            <Header />
            <SideMenu />
            <Outlet />
            <Notify/>
            { autoDoorOpeningPermission && <ControlAutoDoor/>}
            { isLoading && <Loading /> }
        </WrapMain>
    )
}

export default Main